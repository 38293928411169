import { useState } from 'react';
import { Content, ContentImage } from './style';
import underConstruction from '../../assets/underConstruction.json';
import Lottie from 'react-lottie';

const UnderConstruction = () => {
	const [animateState] = useState({ isStopped: false, isPaused: false });

	const defaultOptions = {
		loop: true,
		autoplay: true,
	};

	return (
		<Content>
			<ContentImage>
				<Lottie
					options={{ ...defaultOptions, animationData: underConstruction }}
					isStopped={animateState.isStopped}
					isPaused={animateState.isPaused}
				/>
			</ContentImage>
		</Content>
	);
};

export default UnderConstruction;
